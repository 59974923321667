





























































































.disabled-button {
    filter: opacity(0.5);
}

