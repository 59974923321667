@import "../config.scss";

.price .container {
  display: flex;
  justify-content: space-between;
  max-width: 940px;
  flex-wrap: wrap;
}

.price .selectable {
  cursor: pointer;

  &:hover {
    box-shadow: rgb(22 51 127 / 15%) 0px 10px 15px;
  }
}

// Pro / Starter card size

.price .col {
    max-width: 340px;
}

.price .item {
  box-shadow: rgb(22 51 127 / 10%) 0px 5px 10px;
  transition-property: box-shadow;
  transition-duration: 0.4s;

  text-align: -moz-center;
  text-align: -webkit-center;
  width: auto;
  height: auto;
  padding-bottom: 10px;
  background: #FFFFFF;
  border: 1px solid #E8ECFC;
  box-sizing: border-box;
  border-radius: 4px 4px 0px 0px;
}
.price .item h2 {
  padding: 0;
  margin: 0;
}
.price .item .price_aditonal {
  margin: 15px 30px;
  text-align: center;
  font-weight: 400;
}

// Starter/Pro button

.price .item a {
  border-radius: 4px;
  width: 240px;
  height: 42px;
  font-weight: 600;
  font-size: 14px;
  margin: 0 30px;
  outline: none;
  cursor: pointer;
  transition: 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;

  &.starter {
      border: 2px solid #00b884;
      border-radius: 4px;
      width: 240px;
      height: 42px;
      font-weight: 600;
      font-size: 14px!important;
      color: #00b884!important;
      background: #FFFFFF!important;
      outline: none;
      cursor: pointer;
      transition: 200ms!important;
      &:hover {
          background: #00b884 !important;
          color: #FFFFFF !important;
      }
  }

  &.pro {
      border: 2px solid #2396f3!important;
      border-radius: 4px;
      width: 240px;
      height: 42px;
      font-weight: 600;
      color: #2396f3;
      background: none;
      margin: 0 35px;
      outline: none;
      cursor: pointer;
      transition: 1s;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px!important;
      color: #FFFFFF!important;
      background: #2396f3!important;
      margin: 0 35px!important;
      outline: none;
      cursor: pointer;
      transition: 200ms!important;
      box-shadow: rgb(29 43 54 / 15%) 0px 10px 15px!important;
      &:hover {
        background:#2396f3 !important;
        color: #FFFFFF !important;
      }
  }

  &.disabled {
    cursor: default;
    color: white;
    background-color: #a4b5f7;
    pointer-events: none;
  }
}


.price .item .price_rate {
  background: #FF912B;
}
.price .item .price_rate.pro {
  background: #4C6EF5;
}
.price .item .price_rate {
  background: #22B59B;
  border-radius: 4px 4px 0px 0px;
  height: 50px;
  width: 100%;
  text-align: center;
}
.price .item .price_rate h2 {
  font-weight: 600;
  font-size: 18px;
  line-height: 50px;
  color: #FFFFFF;
}
.price .item h3 {
  font-weight: 700;
  font-size: 26px;
  color: #393949;
  margin: 40px 30px 44px;
  height: 73px;
}
.price .item h3.compact {
  margin-top: 0;
  margin-bottom: 30px;
  height: auto;
}
.price .item h3 span {
  font-weight: 400;
  font-size: 14px;
  color: #5A5B6D;
  line-height: 20px;
  margin-top: 10px;
  display: block;
}


// List features
.price .item ul {
  list-style: none;
  padding: 0;
  margin: 0 30px;
}
.price .item ul h4 {
  font-weight: 700;
  font-size: 16px;
  color: #393949;
  margin: 40px 0 22px;
}
.price .item ul li {
  margin: 0 0 10px;
  font-size: 15px;
  line-height: 26px;
  color: #5A5B6D;
  display: flex;
  align-items: flex-start;
}
.price .item ul li img {
  margin-right: 10px;
  height: 25px;
  width: 25px;
}
.price .item ul li div {
  text-align: left;
}
.price .item ul li span {
  font-weight: 600;
}

.proButton{
    border: 2px solid #00b884;
    border-radius: 4px;
    width: 240px;
    height: 42px;
    font-weight: 600;
    color: #00b884;
    background: none;
    margin: 0 35px;
    outline: none;
    cursor: pointer;
    transition: 1s;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px !important;
    color: #FFFFFF !important;
    background: #00b884 !important;
    margin: 0 35px !important;
    outline: none;
    cursor: pointer;
    transition: 200ms !important;
    box-shadow: rgb(29 43 54 / 15%) 0px 10px 15px !important;
}
.swtich-button{
    display: flex;
    justify-content: center;
    margin-top: 42px;
}

.tradeButton{
    border: 2px solid #e5eaff;
    border-radius: 4px;
    width: 140px;
    font-weight: 600;
    font-size: 14px!important;
    color: #FFFFFF;
    background:#4e6ef5;
    margin-right: 22px;
    outline: none;
    cursor: pointer;
    transition: 200ms!important;

}
.depositButton{
    border: 2px solid #e5effb;
    border-radius: 4px;
    width: 140px;
    font-weight: 600;
    margin-right: 22px;
    font-size: 14px!important;
    color: #3692e7;
    background: #e5effb;
    outline: none;
    cursor: pointer;
    transition: 200ms!important;

}
.withdrawalButton{
    border: 2px solid #fcf2ec;
    border-radius: 4px;
    width: 140px;
    font-weight: 600;
    font-size: 14px!important;
    color: #ffa968;
    background: #fcf2ec;
    outline: none;
    cursor: pointer;
    transition: 200ms!important;

}
dNone{
    display: none !important;
}
dBlock{
    display: block !important;
}

.et_pb_text_10 {
    line-height: 22px;
    font-family: 'SF pro text Bold',Helvetica,Arial,Lucida,sans-serif;
    font-size: 12px;
    line-height: 22px;
    width: 138px;
    background-color: #2396f3;
    border-radius: 10px 10px 10px 10px;
    overflow: hidden;
    color: #FFFFFF;
    margin-bottom: 2px!important;
    z-index: 10;
    position: absolute!important;
    bottom: auto;
    left: 50%;
    right: auto;
    transform: translateX(-50%);
}
.tab-container{
  border-radius: 10px;
}
.tab{
  padding: 10px;
  width: 100px;
  border: none;
  border: 1px solid #e7e7e7;
  cursor: pointer;
  background-color: #FFFFFF;
}
.tab.active-tab{
  background-color: #E8ECFC;
  color: #4C6EF5;
  font-weight: 500;
}
.tab:first-child{
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.tab:last-child{
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
