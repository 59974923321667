$button-color: #4C6EF5;


.status-positive {
    color: green;
    background: rgba(34, 158, 108, 0.2);
    border-radius: 4px;
    border: none;
    width: 30px;
    padding: 4px 10px 4px 12px;
    //margin: 9px 6px 5px 6px;
    inline-size: fit-content;
}

.status-deposit {
    color: #3692e7;
    background: #e5effb;
    border-radius: 4px;
    border: none;
    width: 60px;
    text-transform: uppercase;
    padding: 4px 10px 4px 12px;
    //margin: 9px 6px 5px 6px;
    inline-size: fit-content;
}

.status-withdrawal {
    color: #ffa968;
    background: #fcf2ec;
    border-radius: 4px;
    border: none;
    width: 76px;
    text-transform: uppercase;
    padding: 6px 12px 6px 10px;
    //margin: 9px 6px 5px 6px;
    font-size: 13px;
    inline-size: fit-content;
}
.status-negative {
    color: red;
    background: rgba(239, 40, 69, 0.2);
    border-radius: 4px;
    border: none;
    width: 30px;
    text-transform: uppercase;
    padding: 6px 12px 6px 10px;
    //margin: 9px 6px 5px 6px;
    font-size: 13px;
    inline-size: fit-content;
}
.status-neutral {
    color: #FFAA22;
    background:  rgba(255, 165, 3, 0.2);
    border-radius: 4px;
    border: none;
    width: 60px;
    text-transform: uppercase;
    padding: 4px 10px 4px 12px;
    inline-size: fit-content;
}
