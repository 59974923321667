.status-positive {
  color: green;
  background: rgba(34, 158, 108, 0.2);
  border-radius: 4px;
  border: none;
  width: 30px;
  padding: 4px 10px 4px 12px;
  inline-size: fit-content;
}

.status-deposit {
  color: #3692e7;
  background: #e5effb;
  border-radius: 4px;
  border: none;
  width: 60px;
  text-transform: uppercase;
  padding: 4px 10px 4px 12px;
  inline-size: fit-content;
}

.status-withdrawal {
  color: #ffa968;
  background: #fcf2ec;
  border-radius: 4px;
  border: none;
  width: 76px;
  text-transform: uppercase;
  padding: 6px 12px 6px 10px;
  font-size: 13px;
  inline-size: fit-content;
}

.status-negative {
  color: red;
  background: rgba(239, 40, 69, 0.2);
  border-radius: 4px;
  border: none;
  width: 30px;
  text-transform: uppercase;
  padding: 6px 12px 6px 10px;
  font-size: 13px;
  inline-size: fit-content;
}

.status-neutral {
  color: #FFAA22;
  background: rgba(255, 165, 3, 0.2);
  border-radius: 4px;
  border: none;
  width: 60px;
  text-transform: uppercase;
  padding: 4px 10px 4px 12px;
  inline-size: fit-content;
}

.popup {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  white-space: nowrap;
  margin-right: 30px;
  color: #4C6EF5;
}

.icon {
  max-height: 15px;
  margin-right: 5px;
  filter: invert(37%) sepia(78%) saturate(3652%) hue-rotate(220deg) brightness(103%) contrast(92%);
}

.modal-vue {
  overflow-y: auto;
  position: fixed;
  z-index: 1000;
  /* Sit on top */
  display: none;
  padding-top: 60px;
  /* Location of the box */
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  /* Enable scroll if needed */
  background-color: black;
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
  border-radius: 5px;
  overflow-y: auto;
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  margin-left: 20%;
  margin-right: 10%;
  margin-bottom: 20%;
}

.modal-content-dynamic {
  border-radius: 5px;
  overflow-y: auto;
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  margin-bottom: 100px;
  border: 1px solid #888;
  width: fit-content;
}

.input {
  padding: 5px;
  width: 100px;
  height: 100px;
  margin: 10px;
}

.close:hover, .close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}