














































































































































































































































































































































































































@import "../../config.scss";

$checkout-breakpoint: 900px;

/* UTILS */
.spacing {
    margin-left: 10px;
    margin-right: 10px;
}

@media (min-width: $checkout-breakpoint) {
    .no-wrap {
        white-space: nowrap;
    }
}

/* INLINE PRICES  */
.price {
    display: inline;
}

.price--strikethrough {
    @extend .price;
    opacity: 0.7;
    text-decoration: line-through;
}

.promo{
    background-color: #D6F7EE;
    width: auto;
    padding: 0px 10px 0px 10px;
    border-radius: inherit;
    font-size: 11px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    line-height: 42px;
    color: #0D8065;

    @media (max-width: $checkout-breakpoint) {
        display: none;
    }
}

$tag-color: rgb(13, 128, 101);
/* PRICE TAG */
.card-price {
    display: inline;
    display: inline-block;

    width: auto;
    height: 38px;

    background-color: $tag-color;
    -webkit-border-radius: 3px 4px 4px 3px;
    -moz-border-radius: 3px 4px 4px 3px;
    border-radius: 3px 4px 4px 3px;

    border-left: 1px solid $tag-color;

    /* This makes room for the triangle */
    margin-left: 19px;

    position: relative;

    color: white;
    font-weight: 300;
    font-size: 22px;
    line-height: 38px;

    padding: 0 10px 0 10px;
}

/* Makes the triangle */
.card-price:before {
    content: "";
    position: absolute;
    display: block;
    left: -19px;
    width: 0;
    height: 0;
    border-top: 19px solid transparent;
    border-bottom: 19px solid transparent;
    border-right: 19px solid $tag-color;
}

/* Makes the circle */
.card-price:after {
    content: "";
    background-color: white;
    border-radius: 50%;
    width: 4px;
    height: 4px;
    display: block;
    position: absolute;
    left: -9px;
    top: 17px;
}
