.status-positive {
  color: green;
  background: rgba(34, 158, 108, 0.2);
  border-radius: 4px;
  border: none;
  width: 30px;
  padding: 4px 10px 4px 12px;
  inline-size: fit-content;
}

.status-deposit {
  color: #3692e7;
  background: #e5effb;
  border-radius: 4px;
  border: none;
  width: 60px;
  text-transform: uppercase;
  padding: 4px 10px 4px 12px;
  inline-size: fit-content;
}

.status-withdrawal {
  color: #ffa968;
  background: #fcf2ec;
  border-radius: 4px;
  border: none;
  width: 76px;
  text-transform: uppercase;
  padding: 6px 12px 6px 10px;
  font-size: 13px;
  inline-size: fit-content;
}

.status-negative {
  color: red;
  background: rgba(239, 40, 69, 0.2);
  border-radius: 4px;
  border: none;
  width: 30px;
  text-transform: uppercase;
  padding: 6px 12px 6px 10px;
  font-size: 13px;
  inline-size: fit-content;
}

.status-neutral {
  color: #FFAA22;
  background: rgba(255, 165, 3, 0.2);
  border-radius: 4px;
  border: none;
  width: 60px;
  text-transform: uppercase;
  padding: 4px 10px 4px 12px;
  inline-size: fit-content;
}

/* UTILS */
.spacing {
  margin-left: 10px;
  margin-right: 10px;
}

@media (min-width: 900px) {
  .no-wrap {
    white-space: nowrap;
  }
}
/* INLINE PRICES  */
.price, .price--strikethrough {
  display: inline;
}

.price--strikethrough {
  opacity: 0.7;
  text-decoration: line-through;
}

.promo {
  background-color: #D6F7EE;
  width: auto;
  padding: 0px 10px 0px 10px;
  border-radius: inherit;
  font-size: 11px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  line-height: 16px;
  color: #0D8065;
}
@media (max-width: 900px) {
  .promo {
    display: none;
  }
}

/* PRICE TAG */
.card-price {
  display: inline;
  display: inline-block;
  width: auto;
  height: 38px;
  background-color: #0d8065;
  -webkit-border-radius: 3px 4px 4px 3px;
  -moz-border-radius: 3px 4px 4px 3px;
  border-radius: 3px 4px 4px 3px;
  border-left: 1px solid #0d8065;
  /* This makes room for the triangle */
  margin-left: 19px;
  position: relative;
  color: white;
  font-weight: 300;
  font-size: 22px;
  line-height: 38px;
  padding: 0 10px 0 10px;
}

/* Makes the triangle */
.card-price:before {
  content: "";
  position: absolute;
  display: block;
  left: -19px;
  width: 0;
  height: 0;
  border-top: 19px solid transparent;
  border-bottom: 19px solid transparent;
  border-right: 19px solid #0d8065;
}

/* Makes the circle */
.card-price:after {
  content: "";
  background-color: white;
  border-radius: 50%;
  width: 4px;
  height: 4px;
  display: block;
  position: absolute;
  left: -9px;
  top: 17px;
}