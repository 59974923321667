.signin-signup {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-top: 10vh;

  .register-agreement {
    font-size: 0.75em;
    padding: 20px;
    width: 220px;
    margin: auto;
  }

  hr {
    width: 50%;
    margin: 17px auto;
    opacity: 0.3;
    border: none;
    border-bottom: 1px solid #d6dce2;
  }

  .signin-row {
    display: flex;
    gap: 15px;

    @media (max-width: 700px) {
        flex-direction: column;
    }

    .field {
        flex-grow: 1;
    }
  }

  @mixin responsive-signup {
    @media (max-width: 768px) {
      width: 100vw;
    }
    @media (max-width: 992px) {
      width: 70vw;
    }
  }

  .card-like {
    width: 300px;
    padding: 30px;

    .text-center {
        @include responsive-signup;

        input {
          @include responsive-signup;
        }
    }

    // Pour les formulaire de paiement
    &.form {
        width: 60vw;

        @include responsive-signup;
    }

    &.larger {
      width: 400px;
        box-shadow: rgb(0 0 0 / 4%) 0px 0px 0px 1px, rgb(0 0 0 / 4%) 0px 2px 8px, rgb(99 116 134 / 3%) 0px 5px 8px;
      @include responsive-signup;
    }

    @include responsive-signup;
  }
}
