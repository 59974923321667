

































































































































































































































































































































































































































































































































































































































































































@import "@yaireo/tagify/src/tagify.scss";

.tag-input-style {
    border-style: none;
    background: rgba(232, 236, 252, 0.5);
    font-size: 10px;
    font-family: Cairo, sans-serif;
    font-weight: normal;
    border-radius: 5px;
    margin-top: 10px;
}

.tagify > span {
    min-width: 100px;
}
